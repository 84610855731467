<template>
<v-container>
  <v-row>
    <v-col cols="12">
      hello world
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
name: "Admin"
}
</script>

<style scoped>

</style>